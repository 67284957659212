"use client";

import { Flex, Button, Box, Text } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import { NextPage } from "next";
import Link from "next/link";
import theme from "@/theme";
import Logo from "@/components/PageLayout/Header/Logo";

const PageNotFound: NextPage = () => (
  <>
    <Global
      styles={css`
        body {
          background-color: ${theme.colors.gray[100]};
        }
      `}
    />

    <Flex flexDirection="column" justifyContent="center" bg="#023648" m="auto" p={10} minHeight="100vh">
      <Flex justifyContent="center" mb={[3, null, null, 12]}>
        <Link href="/" passHref>
          <Logo />
        </Link>
      </Flex>

      <Box mx="auto" w="fit-content" maxWidth={1600}>
        <Flex gap={[0, null, null, 10]} alignItems="center" flexDirection="column">
          <Box textAlign="center">
            <Text color="white" fontSize={[18, null, null, 22]}>
              Sorry, we couldn't find this page.
            </Text>

            <Link href="/" passHref legacyBehavior>
              <Button as="a" size="lg" colorScheme="blue" mt={6}>
                Return to home page
              </Button>
            </Link>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </>
);

export default PageNotFound;
